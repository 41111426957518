import { merge } from 'lodash';
import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createEventHandler } from '@wix/tpa-settings';

import { SECRET_SLUG } from 'api/groups/constants';
import { WARMUP_DATA_KEY } from 'store/constants';

import type { IAppData } from 'src/types';

import { ESettingsEvents, SettingsEventHandler } from './Settings/types';

import { ApplicationController } from './controllers/application.controller';
import { GroupController } from './controllers/group.controller';
import { MediaController } from './controllers/media.controller';
import { DiscussionController } from './controllers/discussion.controller';
import { EventsController } from './controllers/events.controller';
import { AboutController } from './controllers/about.controller';
import { MembersController } from './controllers/members.controller';
import { SeoController } from './controllers/seo.controller';

import { waitForTransitionToFinish } from './controllers/helpers';

const createController: CreateControllerFn = (params) => {
  const { router, store } = params.appData as IAppData;
  const { flowAPI } = params;
  const { wixCodeApi, config, setProps } = flowAPI.controllerConfig;
  const { isEditor, isPreview } = flowAPI.environment;
  const { warmupData } = wixCodeApi.window;
  const { isSSR } = flowAPI.environment;

  const application = ApplicationController(params);
  const group = GroupController(params);
  const discussion = DiscussionController(params);
  const media = MediaController(params);
  const events = EventsController(params);
  const about = AboutController(params);
  const members = MembersController(params);
  const seo = SeoController(params);

  const settingsEventHandler = createEventHandler<SettingsEventHandler>(
    config.publicData.COMPONENT,
  );

  settingsEventHandler.on(
    ESettingsEvents.RequestState,
    handleStateChangeRequest,
  );

  settingsEventHandler.onReset(function () {
    handleStateChangeRequest('group.discussion.feed');
  });

  if (isEditor || isPreview) {
    router.urlService.rules.initial({
      state: 'group.discussion.feed',
      params: {
        slug: SECRET_SLUG,
      },
    });
  }

  return {
    async pageReady() {
      await Promise.all([
        application.$.handleUserLogin(),
        discussion.$.initComments(),
      ]);

      await resolve();

      setInitialProps();

      router.urlService.listen();
      store.subscribe(updateStore);
    },
    updateConfig($w, updatedConfig) {
      settingsEventHandler.notify(updatedConfig.publicData.COMPONENT || {});
    },
    onBeforeUnLoad() {
      seo.dispose();
      discussion.$.dispose();
      router.dispose();
    },
  };

  async function resolve() {
    router.urlService.sync();
    await waitForTransitionToFinish('group.**', router);

    if (isSSR) {
      warmupData.set(WARMUP_DATA_KEY, store.getState());
    }
  }

  /**
   * Enable this after Wix comments will enable support of warmup data
   */
  async function _resolve() {
    if (isSSR || !warmupData.get(WARMUP_DATA_KEY)) {
      router.urlService.sync();
      await waitForTransitionToFinish('group.**', router);
    } else {
      router.transitionService._deregisterHookFns.lazyResolve();
      router.transitionService._deregisterHookFns.eagerResolve();
      router.transitionService._deregisterHookFns.resolveAll();

      router.urlService.sync();
      await waitForTransitionToFinish('group.**', router);
      (router.transitionService as any)._registerCoreTransitionHooks();
    }

    if (isSSR) {
      warmupData.set(WARMUP_DATA_KEY, store.getState());
    }
  }

  function handleStateChangeRequest(state: string) {
    router.stateService.go(state);
  }

  function updateStore() {
    setProps({
      store: store.getState(),
    });
  }

  function setInitialProps() {
    discussion.$.bindComments();
    setProps(
      merge(
        {
          fitToContentHeight: true,
          store: store.getState(),
        },
        application,
        group,
        discussion,
        media,
        events,
        about,
        members,
      ),
    );
  }
};

export default createController;

import type { ControllerParams } from '@wix/yoshi-flow-editor';

import type { IAppData } from 'src/types';

import { selectIsAppAvailable, GroupAppKey } from 'store/groups';
import { selectStateDeclarations } from 'store/application/selectors';
import * as events from 'store/events';

export function EventsController(params: ControllerParams) {
  const { router, store } = params.appData as IAppData;
  const { isSSR } = params.flowAPI.environment;
  const states = selectStateDeclarations(store.getState());

  router.stateRegistry.register({
    ...states['group.events'],
    resolvePolicy: {
      async: isSSR ? 'WAIT' : 'NOWAIT',
    },
    resolve: {
      isAuthorized: [
        'groupId',
        (groupId: string) => {
          return selectIsAppAvailable(store.getState(), {
            groupId,
            application: GroupAppKey.EVENTS_APP,
          });
        },
      ],
      events: [
        'groupId',
        'isAuthorized',
        (groupId: string, isAuthorized: boolean) => {
          if (isAuthorized) {
            return fetch(groupId);
          }
        },
      ],
    },
  });

  return {
    events$: {
      fetch,
    },
  };

  function fetch(groupId: string) {
    return store.dispatch(events.thunks.fetch(groupId));
  }
}

export type IEventsController = ReturnType<typeof EventsController>;

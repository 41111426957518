import type { RawParams } from '@uirouter/core';
import type { ControllerParams } from '@wix/yoshi-flow-editor';

import { Role } from '@wix/ambassador-social-groups-v2-group-role/types';

import type { IAppData } from 'src/types';

import { selectStateDeclarations } from 'store/application/selectors';
import { selectIsAppAvailable, GroupAppKey } from 'store/groups';
import * as members from 'store/members';

import type { IMembersQueryParams } from 'store/members/types';

export function MembersController(params: ControllerParams) {
  const { router, store } = params.appData as IAppData;
  const { isSSR } = params.flowAPI.environment;

  const states = selectStateDeclarations(store.getState());

  router.stateRegistry.register({
    ...states['group.members'],
    resolvePolicy: {
      async: isSSR ? 'WAIT' : 'NOWAIT',
    },
    resolve: {
      isAuthorized: [
        'groupId',
        (groupId: string) => {
          return selectIsAppAvailable(store.getState(), {
            groupId,
            application: GroupAppKey.MEMBERS_APP,
          });
        },
      ],
      members: [
        'groupId',
        'isAuthorized',
        '$stateParams',
        (groupId: string, isAuthorized: boolean, params: RawParams) => {
          if (isAuthorized) {
            return fetch(groupId, params);
          }
        },
      ],
    },
  });

  return {
    members$: {
      fetch,
      querySuggested,
      fetchJoinRequests,
      fetchAnswers,
      changeRole,
      add,
      inviteByEmail,
      remove,
      approve,
      reject,
    },
  };

  function fetchAnswers(groupId: string, memberId: string) {
    return store.dispatch(members.thunks.fetchAnswers({ groupId, memberId }));
  }

  function changeRole(groupId: string, memberId: string, role: Role) {
    return store.dispatch(
      members.thunks.changeRole({ groupId, memberId, role }),
    );
  }

  function remove(groupId: string, memberId: string) {
    return store.dispatch(members.thunks.remove({ groupId, memberId }));
  }

  function add(groupId: string, memberIds: string[]) {
    return store.dispatch(members.thunks.addToGroup({ groupId, memberIds }));
  }

  function inviteByEmail(groupId: string, emails: string[]) {
    return store.dispatch(members.thunks.inviteByEmail({ groupId, emails }));
  }

  function fetchJoinRequests(groupId: string) {
    return store.dispatch(members.thunks.fetchJoinRequests(groupId));
  }

  function approve(groupId: string, memberId: string) {
    return store.dispatch(members.thunks.approve({ groupId, memberId }));
  }

  function reject(groupId: string, memberId: string, reason?: string) {
    return store.dispatch(members.thunks.reject({ groupId, memberId, reason }));
  }

  function querySuggested(
    groupId: string,
    params: IMembersQueryParams,
    offset: number = 0,
    limit?: number,
  ) {
    return store.dispatch(
      members.thunks.querySuggested({
        groupId,
        limit,
        offset,
        query: {
          nickname: params.nickname,
        },
      }),
    );
  }

  function fetch(
    groupId: string,
    params: IMembersQueryParams,
    offset: number = 0,
    limit?: number,
  ) {
    return store.dispatch(
      members.thunks.query({
        groupId,
        limit,
        offset,
        query: {
          role: params.role,
          nickname: params.nickname,
        },
      }),
    );
  }
}

export type IMembersController = ReturnType<typeof MembersController>;

import type { ControllerParams } from '@wix/yoshi-flow-editor';
import type { RawParams } from '@uirouter/core';
import {
  JoinRequest,
  MembershipStatus,
} from '@wix/ambassador-social-groups-v2-group-member/types';
import type { GroupSettings } from '@wix/ambassador-social-groups-v2-group/types';
import type { MembershipQuestion } from '@wix/ambassador-social-groups-v2-membership-question/types';
import type {
  NotificationChannel,
  NotificationSettings,
} from '@wix/ambassador-social-groups-v1-notification-settings/types';

import * as groups from 'store/groups';
import * as application from 'store/application';
import { GroupApp, IUpdateGroupInfoParams } from 'store/groups';
import { selectStateDeclarations } from 'store/application/selectors';
import {
  selectHasAdminRole,
  selectIsJoinedGroupMember,
} from 'store/groups/selectors';

import type { IGroup } from 'api/groups/types';
import type { IAppData } from 'src/types';

import { INVITE_PAID_PLANS } from '../../../config/constants';

export function GroupController(params: ControllerParams) {
  const { router, store } = params.appData as IAppData;
  const { isSSR } = params.flowAPI.environment;
  const states = selectStateDeclarations(store.getState());

  router.stateRegistry.deregister('group');
  router.stateRegistry.register({
    ...states.group,
    resolve: {
      group: [
        '$stateParams',
        async (params: RawParams) => {
          const { group } = await fetch(params.slug).unwrap();
          return group;
        },
      ],
      groupId: ['group', async (group: IGroup) => group.id],
      isAdmin: [
        'groupId',
        (groupId: string) => selectHasAdminRole(store.getState(), groupId),
      ],
      isJoined: [
        'groupId',
        (groupId: string) =>
          selectIsJoinedGroupMember(store.getState(), groupId),
      ],
    },
    async onEnter(transition) {
      const group = (await transition.injector().getAsync('group')) as IGroup;

      if (group.membership === MembershipStatus.JOINED && !isSSR) {
        store.dispatch(groups.thunks.resetActivityCounter(group.id as string));
      }
    },
  });

  router.stateRegistry.register(states['group.custom-tab']);

  router.transitionService.onBefore(
    { to: 'group.**' },
    async function (transition) {
      const params = transition.params();
      const group = await transition.injector().getAsync('group');

      if (group.slug !== params.slug) {
        return transition.targetState().withParams({ slug: group.slug });
      }
    },
  );

  router.transitionService.onFinish(
    { to: 'group.**' },
    async function (transition) {
      if (isSSR) {
        return;
      }

      const params = transition.params();
      const groupId = await transition.injector().getAsync('groupId');
      const isJoined = await transition.injector().getAsync('isJoined');

      if (groupId && !isJoined && shouldTriggerJoin(params)) {
        join({
          groupId,
          autoInviteId: params.autoInviteId,
        });
      }
    },
  );

  return {
    group$: {
      join,
      leave,
      remove,
      cancelRequest,
      fetch,
      fetchQuestions,
      updateQuestions,
      updateGroupInfo,
      updateGroupSettings,
      configureApps,
      fetchNotificationsSettings,
      updateNotificationsSettings,
    },
  };

  async function join(params: JoinRequest) {
    try {
      await store.dispatch(groups.thunks.join(params)).unwrap();
      router.stateService.go(
        '.',
        {
          appSectionParams: undefined,
          autoInviteId: undefined,
          invite: undefined,
        },
        { reload: true },
      );
    } catch (error) {
      // It's fine. probably some restriction
    }
  }

  async function fetchQuestions(groupId: string) {
    store.dispatch(groups.thunks.fetchQuestions(groupId));
  }

  async function updateQuestions(
    groupId: string,
    questions: MembershipQuestion[],
  ) {
    store.dispatch(
      groups.thunks.updateQuestions({
        groupId,
        questions,
      }),
    );
  }

  async function leave(groupId: string) {
    await store.dispatch(groups.thunks.leave(groupId));

    store.dispatch(application.actions.closeDialog('leaveGroup'));
    router.stateService.reload();
  }

  async function remove(groupId: string) {
    await store.dispatch(groups.thunks.remove(groupId));
    router.stateService.go('groups');
  }

  async function cancelRequest(groupId: string) {
    await store.dispatch(groups.thunks.cancelRequest(groupId));

    store.dispatch(application.actions.closeDialog('cancelJoinRequest'));
    router.stateService.reload(router.globals.current);
  }

  function fetch(groupId: string) {
    return store.dispatch(groups.thunks.fetchGroup(groupId));
  }

  function updateGroupInfo(
    groupId: string,
    groupChanges: Omit<IUpdateGroupInfoParams, 'groupId'>,
  ) {
    return store.dispatch(
      groups.thunks.updateGroupInfo({ groupId, ...groupChanges }),
    );
  }

  function updateGroupSettings(groupId: string, settings: GroupSettings) {
    return store.dispatch(
      groups.thunks.updateGroupSettings({ groupId, settings }),
    );
  }

  function configureApps(groupId: string, apps: GroupApp[]) {
    return store.dispatch(groups.thunks.updateGroupApps({ groupId, apps }));
  }

  function fetchNotificationsSettings(
    groupId: string,
    channel: NotificationChannel,
  ) {
    return store.dispatch(
      groups.thunks.fetchNotificationSettings({ groupId, channel }),
    );
  }

  function updateNotificationsSettings(
    groupId: string,
    channel: NotificationChannel,
    settings: NotificationSettings[],
  ) {
    return store.dispatch(
      groups.thunks.updateNotificationSettings({ groupId, channel, settings }),
    );
  }

  function shouldTriggerJoin(params: RawParams) {
    try {
      const inviteFromPlans =
        params.appSectionParams?.invite === INVITE_PAID_PLANS;

      return params.invite || params.autoInviteId || inviteFromPlans;
    } catch (e) {
      return false;
    }
  }
}

export type IGroupController = ReturnType<typeof GroupController>;

import type { ControllerParams } from '@wix/yoshi-flow-editor';

import * as groups from 'store/groups';
import { selectStateDeclarations } from 'store/application/selectors';

import type { IAppData } from 'src/types';

export function AboutController(params: ControllerParams) {
  const { router, store } = params.appData as IAppData;
  const { isSSR } = params.flowAPI.environment;
  const states = selectStateDeclarations(store.getState());

  router.stateRegistry.register({
    ...states['group.about'],
    resolvePolicy: {
      async: isSSR ? 'WAIT' : 'NOWAIT',
    },
    resolve: {
      about: [
        'groupId',
        (groupId: string) => {
          return Promise.all([fetchActivity(groupId), fetchRules(groupId)]);
        },
      ],
    },
  });

  return {
    about$: {
      fetchActivity,
      fetchRules,
    },
  };

  function fetchActivity(groupId: string) {
    return store.dispatch(groups.thunks.fetchActivity(groupId));
  }

  function fetchRules(groupId: string) {
    return store.dispatch(groups.thunks.fetchRules(groupId));
  }
}

export type IAboutController = ReturnType<typeof AboutController>;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { FlowAPI } from '@wix/yoshi-flow-editor';

import * as media from 'api/media';

import * as application from '../application';

export const fetch = createAsyncThunk(
  'media:fetch',
  async function (groupId: string, thunkAPI) {
    const flowApi = thunkAPI.extra as FlowAPI;
    const { dispatch } = thunkAPI;
    const { translations } = flowApi;
    const { t } = translations;

    try {
      const response = await flowApi.httpClient.request(media.fetch(groupId));

      return response.data;
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.media.fetch'),
        }),
      );

      flowApi.errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

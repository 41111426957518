import type { ControllerParams } from '@wix/yoshi-flow-editor';
import type { RawParams } from '@uirouter/core';

import type { Query } from '@wix/ambassador-feed-v1-feed-item/types';
import {
  CommentsControllerApi,
  initializeCommentsController,
  PaginationState,
} from '@wix/comments-ooi-client/controller';
import type { DraftContent } from 'ricos-editor';

import { EFilterKeys } from 'api/feed';

import type { IAppData } from 'src/types';

import {
  selectIsAppAvailable,
  GroupAppKey,
  selectCanInviteMembers,
} from 'store/groups';
import { selectPinnedItem } from 'store/feed/selectors';
import { selectStateDeclarations } from 'store/application/selectors';
import * as feed from 'store/feed';
import * as topics from 'store/topics';
import * as events from 'store/events';
import * as members from 'store/members';

export function DiscussionController(params: ControllerParams) {
  const { environment } = params.flowAPI;
  const { router, store } = params.appData as IAppData;
  const { isSSR } = environment;

  const states = selectStateDeclarations(store.getState());
  let commentsApi: CommentsControllerApi;

  router.stateRegistry.register({
    ...states['group.discussion'],
    resolvePolicy: {
      async: isSSR ? 'WAIT' : 'NOWAIT',
    },
    resolve: {
      isAuthorized: [
        'groupId',
        (groupId: string) =>
          selectIsAppAvailable(store.getState(), {
            groupId,
            application: GroupAppKey.FEED_APP,
          }),
      ],
      membersAvailable: [
        'groupId',
        (groupId: string) =>
          selectIsAppAvailable(store.getState(), {
            groupId,
            application: GroupAppKey.MEMBERS_APP,
          }),
      ],
      eventsAvailable: [
        'groupId',
        (groupId: string) =>
          selectIsAppAvailable(store.getState(), {
            groupId,
            application: GroupAppKey.EVENTS_APP,
          }),
      ],
      canInvite: [
        'groupId',
        (groupId: string) => selectCanInviteMembers(store.getState(), groupId),
      ],
      topics: [
        'groupId',
        'isAuthorized',
        (groupId: string, isAuthorized: boolean) => {
          if (isAuthorized) {
            return fetchTopics(groupId);
          }
        },
      ],
      groupMembers: [
        'groupId',
        'membersAvailable',
        (groupId: string, membersAvailable: boolean) => {
          if (membersAvailable) {
            return store.dispatch(
              members.thunks.query({
                groupId,
                limit: 5,
                offset: 0,
              }),
            );
          }
        },
      ],
      events: [
        'groupId',
        'eventsAvailable',
        (groupId: string, eventsAvailable: boolean) => {
          if (eventsAvailable) {
            return store.dispatch(events.thunks.fetch(groupId));
          }
        },
      ],
    },
  });

  router.stateRegistry.register({
    ...states['group.discussion.feed'],
    resolvePolicy: {
      async: isSSR ? 'WAIT' : 'NOWAIT',
    },
    resolve: {
      feedItems: [
        '$stateParams',
        'isAuthorized',
        (stateParams: RawParams, isAuthorized: boolean) => {
          const {
            [EFilterKeys.CURSOR]: cursor,
            [EFilterKeys.TOPICS]: topicId,
          } = stateParams;

          if (!isAuthorized) {
            return;
          }

          if (topicId) {
            return filter({
              filter: {
                [EFilterKeys.TOPICS]: topicId,
              },
            });
          }

          return fetch(cursor);
        },
      ],
    },
  });

  router.stateRegistry.register({
    ...states['group.discussion.post'],
    resolvePolicy: {
      async: isSSR ? 'WAIT' : 'NOWAIT',
    },
    resolve: {
      $: [
        '$stateParams',
        'groupId',
        'isAuthorized',
        (stateParams: RawParams, groupId: string, isAuthorized: boolean) => {
          if (isAuthorized) {
            return get(groupId, stateParams.feedItemId);
          }
        },
      ],
    },
  });

  return {
    $: {
      async initComments() {
        commentsApi = await initializeCommentsController(
          params.controllerConfig,
          {
            shouldAutoBindStateToSetProps: false,
            appDefinitionId: params.controllerConfig.appParams.appDefinitionId,
            httpClient: params.flowAPI.httpClient,
          },
        );

        commentsApi.watch.pagination.onChange(handleCommentsChange);
      },
      bindComments() {
        commentsApi.bindStateToSetProps();
      },
      dispose() {
        commentsApi.unmountAllResources();
      },
    },
    feed$: {
      remove,
      fetch,
      create,
      update,
      react,
      unreact,
      pin,
      unpin,
      subscribe,
      unsubscribe,
      updateDraft,
    },
    topics$: {
      create: createTopic,
      fetch: fetchTopics,
    },
  };

  function handleCommentsChange(paginationState: PaginationState) {
    return store.dispatch(
      feed.actions.updateTotalComments(
        Object.fromEntries(
          Object.entries(paginationState).filter((data) => {
            const [, state] = data;

            return state.type === 'READY';
          }),
        ),
      ),
    );
  }

  function fetchTopics(groupId: string) {
    return store.dispatch(topics.thunks.fetch(groupId));
  }

  function createTopic(groupId: string, label: string) {
    return store.dispatch(
      topics.thunks.create({
        groupId,
        label,
      }),
    );
  }

  function create(groupId: string, content: string, topics: string[]) {
    return store.dispatch(
      feed.thunks.create({
        groupId,
        content,
        topics,
        commentsApi,
      }),
    );
  }

  function update(
    groupId: string,
    feedItemId: string,
    content: string,
    topics: string[],
  ) {
    store.dispatch(
      feed.thunks.update({
        groupId,
        feedItemId,
        content,
        topics,
      }),
    );
  }

  async function filter(query?: Query) {
    const transition = router.globals.transitionHistory.peekHead();
    const groupId = await transition.injector().getAsync('groupId');

    return store.dispatch(
      feed.thunks.fetch({
        groupId,
        query,
        commentsApi,
      }),
    );
  }

  function get(groupId: string, feedItemId: string) {
    return store.dispatch(
      feed.thunks.get({
        commentsApi,
        groupId,
        feedItemId,
      }),
    );
  }

  async function fetch(cursor?: string) {
    const transition = router.globals.transitionHistory.peekHead();
    const groupId = await transition.injector().getAsync('groupId');

    return store.dispatch(
      feed.thunks.fetch({
        groupId,
        commentsApi,
        cursor: {
          cursor,
          limit: 5,
        },
      }),
    );
  }

  function subscribe(groupId: string, feedItemId: string) {
    return store.dispatch(
      feed.thunks.subscribe({
        feedItemId,
        groupId,
      }),
    );
  }

  function unsubscribe(groupId: string, feedItemId: string) {
    return store.dispatch(
      feed.thunks.unsubscribe({
        feedItemId,
        groupId,
      }),
    );
  }

  async function pin(groupId: string, feedItemId: string) {
    const pinnedItem = selectPinnedItem(store.getState());

    if (pinnedItem) {
      await unpin(groupId, pinnedItem.feedItemId as string);
    }

    return store.dispatch(
      feed.thunks.pin({
        feedItemId,
        groupId,
      }),
    );
  }

  function unpin(groupId: string, feedItemId: string) {
    return store.dispatch(
      feed.thunks.unpin({
        feedItemId,
        groupId,
      }),
    );
  }

  function react(groupId: string, feedItemId: string, code: string) {
    return store.dispatch(
      feed.thunks.react({
        code,
        groupId,
        feedItemId,
      }),
    );
  }

  function unreact(groupId: string, feedItemId: string, code: string) {
    return store.dispatch(
      feed.thunks.unreact({
        code,
        groupId,
        feedItemId,
      }),
    );
  }

  function remove(groupId: string, feedItemId: string) {
    return store.dispatch(
      feed.thunks.remove({
        groupId,
        feedItemId,
      }),
    );
  }

  function updateDraft(draftContent?: DraftContent) {
    return store.dispatch(feed.actions.updateDraft({ draftContent }));
  }
}

export type IDiscussionController = Omit<
  ReturnType<typeof DiscussionController>,
  '$'
>;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { FlowAPI } from '@wix/yoshi-flow-editor';

import {
  Event,
  EventFieldset,
  SortOrder,
} from '@wix/ambassador-events-v1-event/types';
import { queryEventsV2 } from '@wix/ambassador-events-v1-event/http';

import { listEvents as listEventsByGroupId } from '@wix/ambassador-social-groups-v1-group-event/http';
import { IHttpClient } from '@wix/fe-essentials/http-client';

import * as application from '../application';

export const fetch = createAsyncThunk(
  'events:fetch',
  async function (groupId: string, thunkAPI) {
    const flowApi = thunkAPI.extra as FlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = flowApi.translations;

    try {
      const response = await getEventsByGroupId(flowApi.httpClient, groupId);

      return response;
    } catch (err) {
      const error = err as Error;
      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.events.fetch'),
        }),
      );
      flowApi.errorMonitor.captureException(error as Error);
      return Promise.reject(error);
    }
  },
);

async function getEventsByGroupId(
  httpClient: IHttpClient,
  groupId: string,
): Promise<Event[]> {
  const { data } = await httpClient.request(listEventsByGroupId({ groupId }));
  const eventIds = data?.eventIds ?? [];

  if (eventIds.length === 0) {
    return [];
  }

  const queryEventsResponse = await httpClient.request(
    queryEventsV2({
      facet: ['status'],
      fieldset: [
        EventFieldset.DETAILS,
        EventFieldset.REGISTRATION,
        EventFieldset.URLS,
      ],
      query: {
        filter: {
          eventId: {
            $hasSome: eventIds,
          },
          status: {
            $ne: 'CANCELED',
          },
        },
        sort: [{ fieldName: 'start', order: SortOrder.ASC }],
        paging: {
          limit: 100,
        },
      },
    }),
  );

  return queryEventsResponse.data?.events ?? [];
}

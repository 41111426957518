import { createAsyncThunk } from '@reduxjs/toolkit';
import { FlowAPI } from '@wix/yoshi-flow-editor';

import {
  approveJoinGroupRequests,
  rejectJoinGroupRequests,
} from '@wix/ambassador-social-groups-v2-join-group-request/http';
import * as inviteApi from '@wix/ambassador-social-groups-v1-email-invite/http';
import {
  Role,
  SortOrder,
} from '@wix/ambassador-social-groups-v2-group-member-with-profile/types';

import * as membersApi from 'api/members/members.api';
import * as groupsApi from 'api/groups/groups.api';
import * as membershipApi from 'api/membership/membership.api';

import * as application from '../application';
import * as groups from '../groups';
import type { IRootState } from '../types';

import type { IQueryGroupMembersParams } from './types';
import { selectSuggestedMembersMetadata } from './selectors';

export const query = createAsyncThunk(
  'members:query',
  async function (params: IQueryGroupMembersParams, thunkAPI) {
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as FlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = translations;

    try {
      const { data } = await httpClient.request(
        membersApi.queryGroupMembers(
          params.groupId,
          {
            paging: {
              offset: params.offset,
              limit: params.limit || 20,
            },
            sort: [
              {
                fieldName: params.query?.sort || 'profile.nickname',
                order: SortOrder.ASC,
              },
            ],
            filter: {
              'profile.nickname': {
                $contains: params.query?.nickname || '',
              },
            },
          },
          params.query?.role,
        ),
      );

      return data;
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.members.query'),
        }),
      );

      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const querySuggested = createAsyncThunk(
  'members:querySuggested',
  async function (params: IQueryGroupMembersParams, thunkAPI) {
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as FlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = translations;

    try {
      const { data } = await httpClient.request(
        membersApi.queryNonGroupMembers(params.groupId, {
          paging: {
            offset: params.offset,
            limit: params.limit || 20,
          },
          sort: [
            {
              fieldName: params.query?.sort || 'profile.nickname',
              order: SortOrder.ASC,
            },
          ],
          filter: {
            'profile.nickname': {
              $contains: params.query?.nickname || '',
            },
          },
        }),
      );

      return data;
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.members.queryNonGroupMembers'),
        }),
      );

      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const inviteByEmail = createAsyncThunk(
  'members:inviteByEmail',
  async function (params: { groupId: string; emails: string[] }, thunkAPI) {
    const { emails, groupId } = params;
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as FlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = translations;

    try {
      const { data } = await httpClient.request(
        inviteApi.batchInviteByEmail({
          groupId,
          emails,
        }),
      );

      return data;
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.members.inviteByEmail'),
        }),
      );

      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const addToGroup = createAsyncThunk(
  'members:addToGroup',
  async function (params: { groupId: string; memberIds: string[] }, thunkAPI) {
    const { groupId, memberIds } = params;
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as FlowAPI;
    const { dispatch, getState } = thunkAPI;
    const { t } = translations;
    const { metadata } = selectSuggestedMembersMetadata(
      getState() as IRootState,
    );

    try {
      const response = await httpClient.request(
        membershipApi.addMembersToGroup(groupId, memberIds),
      );

      if (metadata.hasNext) {
        dispatch(
          querySuggested({
            groupId,
            limit: 5,
            offset: metadata.count! + metadata.offset!,
          }),
        );
      }

      dispatch(
        groups.actions.updateMembersCounter({
          groupId,
          count: memberIds.length,
        }),
      );

      return response.data;
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.members.add'),
        }),
      );

      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const remove = createAsyncThunk(
  'members:remove',
  async function (params: { groupId: string; memberId: string }, thunkAPI) {
    const { groupId, memberId } = params;
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as FlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = translations;

    try {
      await httpClient.request(
        membershipApi.removeFromGroup(groupId, memberId),
      );

      dispatch(groups.actions.updateMembersCounter({ groupId, count: -1 }));
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.members.remove'),
        }),
      );

      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const changeRole = createAsyncThunk(
  'members:changeRole',
  async function (
    params: { groupId: string; memberId: string; role: Role },
    thunkAPI,
  ) {
    const { groupId, memberId, role } = params;
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as FlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = translations;

    try {
      await httpClient.request(
        role === Role.ADMIN
          ? membershipApi.addAdminRole(groupId, memberId)
          : membershipApi.removeAdminRole(groupId, memberId),
      );
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.members.changeRole'),
        }),
      );

      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const fetchAnswers = createAsyncThunk(
  'members:fetchAnswers',
  async function (params: { groupId: string; memberId: string }, thunkAPI) {
    const { groupId, memberId } = params;
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as FlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = translations;

    try {
      const { data } = await httpClient.request(
        membershipApi.listMemberAnswers(groupId, memberId),
      );

      return data.answers;
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.members.fetchAnswers'),
        }),
      );
      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const fetchJoinRequests = createAsyncThunk(
  'members:joinRequests:fetch',
  async function (groupId: string, thunkAPI) {
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as FlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = translations;

    try {
      const { data } = await httpClient.request(
        groupsApi.listPendingRequests(groupId),
      );

      return data;
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.members.joinRequests.fetch'),
        }),
      );

      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const approve = createAsyncThunk(
  'members:joinRequests:approve',
  async function (params: { groupId: string; memberId: string }, thunkAPI) {
    const { groupId, memberId } = params;
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as FlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = translations;

    try {
      await httpClient.request(
        approveJoinGroupRequests({
          groupId,
          memberIds: [memberId],
        }),
      );

      const { data } = await httpClient.request(
        membersApi.getGroupMember(groupId, memberId),
      );

      return data;
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.members.joinRequests.approve'),
        }),
      );

      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const reject = createAsyncThunk(
  'members:joinRequests:reject',
  async function (
    params: { groupId: string; memberId: string; reason?: string },
    thunkAPI,
  ) {
    const { groupId, memberId, reason } = params;
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as FlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = translations;

    try {
      const { data } = await httpClient.request(
        rejectJoinGroupRequests({
          groupId,
          rejections: [
            {
              reason,
              memberId,
            },
          ],
        }),
      );

      return data;
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.members.joinRequests.reject'),
        }),
      );

      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);
